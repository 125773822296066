
class AuthService {
  getUser = () => {
    const user = localStorage.getItem("user") || "";
    return user;
  };
  getAdmin = () => {
    const user = localStorage.getItem("user")
    const  role = localStorage.getItem('role')
    if(!user||role!=="admin")
    {
      return false
    }
    return true;
  };
  setUser = (token) => {
    localStorage.setItem("user", token);
  };
  setAdmin = (user)=>
  {
    const {role,access_token} = user
    localStorage.setItem('user',access_token)
    localStorage.setItem('role',role)
  }
  clearUser = () => {
    localStorage.clear();
  };
}

const authService = new AuthService();

export default authService;
