
import React from 'react'
import { Navigate } from 'react-router-dom'
function AdminGuard({children,adminGuard }) {
   const token= localStorage.getItem('user')
   const role = localStorage.getItem('role')
   const guard = adminGuard
   if(guard)
   {
     if (!token||role!=="admin") {
       return <Navigate to="/admin/login" replace />
     }
   }
  return children
}
export default AdminGuard