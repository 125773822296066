import React from 'react'
import logo from "../../assets/images/TommyIcon.png"
import { useTranslation } from "react-i18next";
import { link } from '../helper/constantsLink/links';
function RegistrationForm({handleSubmit,inputForRegistration,handleInput,userDetails,currentLanguage}) {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
    <div className="p-6 xl:px-16 2xl:px-36  pt-4 text-left">
      <p className="text-2xl lg:p-2 text-white tracking-widest lg:tracking-[0.3em] flex justify-center  ">
       <img className="h-7" alt={"THlogo-I"} src={logo} />{" "}
      </p>

      <p className=" text-white font-[futura-bold]  text-3xl lg:text-4xl text-center lg:text-center mt-5 mb-3 lg:mt-6 whitespace-normal md:whitespace-nowrap ">
        <span className="text-[#D61233]">{t("complete").toUpperCase()}</span>{" "}
        <span>{t("to_spin").toUpperCase()}</span>
      </p>
      {inputForRegistration.map((input, index) => (
        <>
          <input
            key={index}
            className="w-[100%] p-2 lg:h-12  font-[futura] rounded mt-3 custom-input"
            placeholder={t(input.placeholder)}
            onChange={input.onChange}
            type={input.type}
            required={input.required}
            name={input.name}
          ></input>
        </>
      ))}
      <div className="flex font-[futura] text-white text-sm font-medium mt-2">
        <div className="pr-2">
          <input
          className="accent-[#D61233] w-6 h-6 custom-input"
            name="age"
            type="checkbox"
            onChange={handleInput}
            checked={userDetails.age}
          />
        </div>
        <p className="lg:text-[15px] text-[12px] "> {t("term_1")}</p>
      </div>
      <div className="flex text-white  text-sm mt-2">
        <div className="pr-2 ">
          <input
            id="red-checkbox"
            className="accent-[#D61233] w-6 h-6"
            name="terms"
            type="checkbox"
            onChange={handleInput}
            checked={userDetails.terms}
          />
        </div>
        <p className="lg:text-[15px] text-[12px] font-[futura] leading-3 lg:leading-4">{t("term_2_a")}<a href={currentLanguage==='fr'?link.terms_fr:link.terms_en} target='_blank'><span className="underline">{t("term_2_b")}</span></a>{t("term_2_c")}<a href={currentLanguage==='fr'?link.privacy_fr:link.privacy_eng} target="_blank"><span className='underline'>{t("term_2_d")}</span></a>{t("term_2_e")}</p>
      </div>
      <div className="flex justify-center ">
        <button
          type="submit"
          className="text-xl lg:text-3xl px-14 py-4 mt-4 font-extrabold text-center my-6  p-2 text-[#02154E] bg-slate-100"
        >
          {t("spin").toUpperCase()}
        </button>
      </div>
      <p className="lg:text-[15px] leading-3 lg:leading-4 font-[futura] tracking-tight text-white text-[12px]">
        {t("term_3_a")}<a target="_blank" href={currentLanguage==='fr'?link.terms_fr:link.terms_en}><span className='underline'>{t("term_3_b")}</span></a>{t("term_3_c")}
      </p>
    </div>
  </form>
  )
}

export default RegistrationForm