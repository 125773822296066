
import React from 'react'


import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Coupon',
        selector: row => row.coupon,
    },
    {
        name: 'Used',
        selector: row => row.isUsed,
    },
    {
        name: 'Created On',
        selector: row => row.time,
    },
    {
        name: 'Used On',
        selector: row => row.useTime,
    },

];

const data = [
    {
        id: 1,
      
        coupon:"ADDSFA2121",
        time:"12/11/2023 12:09 AM",
        useTime:"13/11/2023 12:09 AM",
        isUsed:true?"Yes":"No",
    },
    {
        id: 2,
       
        coupon:"ADD22A2121",
        time:"12/11/2023 12:43 AM",
        useTime:"-",
        isUsed:false?"Yes":"No",


    },
]


function CouponTable() {
  return (
    <DataTable
    columns={columns}
    data={data}
    theme='dark'
    pagination
/>
  )
}

export default CouponTable