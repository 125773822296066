import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));
//get both the urls
const parsedUrl = window.location.href;
const frenchUrl = process.env.REACT_APP_FRENCH_URL;
//match the urls
const frenchUrlLength = frenchUrl.length
const parsedUrlInitials=parsedUrl.slice(0,frenchUrlLength)
if (parsedUrlInitials === frenchUrl) i18n.changeLanguage("fr");

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
