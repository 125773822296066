import AdminLogin from "../../../components/Admin/login/AdminLogin";
import Dashboard from "../../../components/Admin/dashboard/Dashboard";
import UserDetails from "../../../components/Admin/dashboard/userDetails/UserDetails";
import ManageCoupons from "../../../components/Admin/dashboard/coupons/ManageCoupons";

const AdminRoutes = [
  {
    path: "/admin/login",
    component: <AdminLogin/>,
    layout:"AppLayout",
    showNavbar:false,
    adminGuard:false,
  },
  {
    path: "/admin/dashboard",
    component: <Dashboard/>,
    layout:"AdminLayout",
    showNavbar:true,
    adminGuard:true
  },
  {
    path: "/admin/coupon",
    component: <ManageCoupons/>,
    layout:"AdminLayout",
    showNavbar:true,
    adminGuard:true
  },
  {
    path: "/admin/users",
    component: <UserDetails/>,
    layout:"AdminLayout",
    showNavbar:true,
    adminGuard:true
  },
];

export default AdminRoutes;
