export const EndPoints = {
  SPIN_REQUEST: "customer/create",
  CREATE_WIN: "rewards/spin",
  CONFIRM_WIN: "customer-rewards",
};

export const AdminEndPoints = {
  ADMIN_LOGIN: "/user/login",
  TWO_FAC:"user/verify_two_fa",
  CREATE_BULK_PROMOTIONAL_CODES:"/promotions/bulk",
  VIEW_PARTICIPANTS:"customer/with-rewards"

};
