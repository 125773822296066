import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/TommyIcon.png";
import { useTranslation } from "react-i18next";
import { customInterceptors } from "../../../services/config";
import { AdminEndPoints } from "../../../services/apiEndPoints";
import { toast } from "react-toastify";
import authService from "../../../services/authServices";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const API = customInterceptors(); // Create an instance of customInterceptors for API calls
  const navigate = useNavigate(); // Get the router's navigate function
  const [mailSent, setMailSent] = useState(false);
  const [userPayload, setUserPayload] = useState({ email: "", password: "" }); // Define state for user input

  // Function to handle input changes and update the userPayload state
  const handleInput = (e) => {
    const { value, name } = e.target;
    setUserPayload({ ...userPayload, [name]: value });
  };

  // check if the user is already logged in as an admin and redirect to the admin dashboard
  useEffect(() => {
    authService.getAdmin() && navigate("/admin/dashboard");
  }, [navigate]);

  // Define the input fields for the admin login form
  const AdminLoginInputs = [
    {
      name: "email",
      placeholder: "Email",
      type: "email",
      onChange: handleInput,
      required: true,
    },
    {
      name: "password",
      placeholder: "Enter Password",
      type: "password",
      onChange: handleInput,
      required: true,
    },
  ];

  const sendOtp=async()=>{
    try {
      // Make an API POST request to the admin login endpoint with userPayload - this will send otp
      const response = await API.post(AdminEndPoints.ADMIN_LOGIN, userPayload);
      // on status true set mailsent true
      if (response?.data?.status) {
        toast.success(response?.data?.message)
        setMailSent(true)
        return
      }
       if(response?.data?.message)
       {
        toast.error(response?.data?.message)
       }
    } catch (error) {
      if (error?.response?.data?.message) {
        // Display an error message if there's an issue with the login
        toast.error(error?.response?.data?.message);
      }
    }
  }
  const initiateLogin=async()=>{
    try {
      // Make an API POST request to the admin two_fact_auth endpoint with userPayload
      const response = await API.post(AdminEndPoints.TWO_FAC, userPayload);
      //if status is true and access token is there then initiate login
      if (response?.data?.status&&response?.data?.data?.user?.access_token) {
        setMailSent(false)
        // If the login is successful, set admin credentials and redirect to the admin dashboard
        authService.setAdmin({
          role: "admin",
          access_token: response?.data?.data?.user?.access_token,
        });
        toast.success("User Logged In Successfully");
        navigate("/admin/dashboard");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        // Display an error message if there's an issue with the login
        toast.error(error?.response?.data?.message);
      }
    }

  }

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // send otp if mail is not send either initiate login
    mailSent?initiateLogin():sendOtp()
  };

  const { t } = useTranslation(); // Get the translation function

  return (
    <div className="grid h-100 p-6 lg:px-16 xl:px-36 pt-4 text-left">
      <p className="p-2 flex justify-center">
        <img className="h-8" alt={"THlogo-I"} src={logo} />{" "}
      </p>

      <form onSubmit={handleSubmit}>
        <p className="text-white font-[futura-bold]  text-3xl lg:text-3xl text-center lg:text-left my-3 lg:mt-6 ">
          <span className="text-[#D61233]">{t("admin").toUpperCase()}</span>{" "}
          {t("login").toUpperCase()}
        </p>
    
        {mailSent ? (
          <div>
            <input
              className="w-[100%] p-2 font-[futura] rounded mt-2"
              placeholder="Enter Otp Recieved on Mail"
              onChange={handleInput}
              type="text"
              required
              name="two_fa_code"
            ></input>
          </div>
        ) : 
          AdminLoginInputs.map((input, index) => (
            <div key={index}>
              <input
                className="w-[100%] p-2 font-[futura] rounded mt-2"
                placeholder={input.placeholder}
                onChange={input.onChange}
                type={input.type}
                required={input.required}
                name={input.name}
              ></input>
            </div>
          ))
        }
        {}
        <div className="flex justify-center">
          <button
            type="submit"
            className="text-xl lg:text-2xl font-extrabold text-center my-6 w-56 p-2 text-[#02154E] bg-slate-100 tracking-widest"
          >
            {t("login").toUpperCase()}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdminLogin;