import React from "react";
import logo from "../../../assets/images/TommyIcon.png"
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div className="min-h-[80vh] p-2 grid grid-cols-1 md:grid-cols-2 justify-center text-center place-content-center gap-5">
      <div className="grid col-span-1 md:col-span-2">
        <p className="text-2xl p-2 text-white tracking-widest lg:tracking-[0.3em] flex justify-center  ">
          <img className="h-8" alt={"THlogo-I"} src={logo} />{" "}
        </p>
      </div>
      <Link to="/admin/users">
      <div className="border-2 border-dashed p-24 text-3xl text-[#D61233] ">
        See Participants
      </div>
      </Link>
      <Link to="/admin/coupon">
      <div className="border-2 border-dashed p-24 text-3xl text-[#D61233] ">
        Manage Coupons
      </div>
      </Link>
    </div>
  );
}

export default Dashboard;
