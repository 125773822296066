import React, { useState,useEffect } from "react";
import Roullete from "../spin/Roullete";
import CreateWin from "../win/CreateWin";
import { customInterceptors } from "../../services/config";
import { EndPoints } from "../../services/apiEndPoints";
import { toast } from "react-toastify";
import authService from "../../services/authServices";
import RegistrationForm from "./RegisterationForm";
import i18next from "i18next";
import { Howl,Howler } from 'howler';
import appMusic from "../../assets/audio/app_music.mp3"
const API = customInterceptors();
function Registeration() {
  //states
  const currentLanguage = i18next.language;
  const [userVerified, setUserVerified] = useState(false);
  const [showPrize, setShowPrize] = useState(false);
  const [prizeDescription, setPrizeDescription] = useState({});
  const [userDetails, setUserDetails] = useState({
    full_name: "",
    email: "",
    confirmEmail: "",
    postal_code: "",
    region: "us",
    promotion_code: "",
    age: false,
    terms: false,
  });
  //functions
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (["age", "terms"].includes(name)) {
      setUserDetails({ ...userDetails, [name]: !userDetails[name] });
      return;
    }
    if(name==="email")
    {
      setUserDetails({ ...userDetails, [name]: value.toLowerCase() });
      return
    }
    setUserDetails({ ...userDetails, [name]: value });
  };
  // check errors
  const handleErrorCheck = (userDetails) => {
    if (
      userDetails.email.toLowerCase() !== userDetails.confirmEmail.toLowerCase()
    ) {
      toast.error("Email did not match");
      return true;
    }
    if(userDetails?.full_name?.length>100)
    {
      toast.error("Name should be less than 100 Characters")
      return true
    }
    if (userDetails?.promotion_code?.length > 20) {
      toast.error("Promotional Code should not be less than 20 characters");
      return true;
    }
    if (userDetails?.postal_code?.length > 10) {
      toast.error("Postal Code should be less than 10 characters");
      return true;
    }
    const isValidPostalCode =
      userDetails?.postal_code &&
      /^[a-zA-Z0-9]+$/.test(userDetails.postal_code);
    
    if (!isValidPostalCode) {
      toast.error("Postal Code should only include A-Z and 0-9 characters");
      return true;
    }
    if (!userDetails.age || !userDetails.terms) {
      toast.error("Please Accept all Terms and Conditions");
      return true;
    }
    return false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Clear user data before handling the form submission
    authService.clearUser();
  
    // Check if there are any input errors using handleErrorCheck function
    if (!handleErrorCheck(userDetails)) {
      try {
        // Make an API POST request to SPIN_REQUEST endpoint with user details
        const res = await API.post(EndPoints.SPIN_REQUEST, userDetails);
  
        // Check if the response contains an access token
        if (res?.data?.token?.accessToken) {
          // Set the user with the access token and mark the user as verified
          authService.setUser(res?.data?.token?.accessToken);
          setUserVerified(true);
        }
      } catch (err) {
        if (err?.response?.data?.error?.response?.error) {
          // Display an error message if there's an error in the response
          toast.error(err.response.data.error.response.error);
          return;
        }
        if (err?.response?.data?.message) {
          // Display an error message if there's a generic error message
          toast.error(err.response.data.message);
        }
      }
    }
  };
 // input fields for user registration during a spin
const inputForRegistration = [
  {
    name: "full_name",
    placeholder: "full_name",
    type: "text",
    onChange: handleInput,
    required: true,
  },
  {
    name: "email",
    placeholder: "email",
    type: "email",
    onChange: handleInput,
    required: true,
  },
  {
    name: "confirmEmail",
    placeholder: "confirm_email_address",
    type: "email",
    onChange: handleInput,
    required: true,
  },
  {
    name: "postal_code",
    placeholder: "postal_code",
    type: "text",
    onChange: handleInput,
    required: true,
  },
  {
    name: "promotion_code",
    placeholder: "enter_promotional_code",
    type: "text",
    onChange: handleInput,
    required: true,
  },
];
useEffect(() => {

  const sound = new Howl({
    src: [appMusic],
    loop:true,
    autoplay:true,
    volume:0.4,
  });
  return () => {
    sound.unload();
  };
 
}, []);

  return showPrize ? (
    // Render the reward-win page when a user spins and gets a reward
    <CreateWin
      prizeText={prizeDescription}
      currentLanguage={currentLanguage}
    />
  ) : !userVerified ? (
    // Render the registration form when the user is not verified
    <RegistrationForm
      handleSubmit={handleSubmit}
      currentLanguage={currentLanguage}
      handleInput={handleInput}
      userDetails={userDetails}
      inputForRegistration={inputForRegistration}
    />
  ) : (
    // Render the spinner when user details are valid
    <Roullete
      userDetails={userDetails}
      setPrizeDescription={setPrizeDescription}
      setShowPrize={setShowPrize}
      currentLanguage={currentLanguage}
    />
  );
}
  

export default Registeration;