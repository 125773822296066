import Home from "../../../components/home/Home";
import Register from "../../../components/register/Register";

const UserRoutes = [
  {
    path: '/',
    component: <Home/>,
    layout:"AppLayout",
    adminGuard:false
  },
  {
    path: '/register',
    component: <Register/>,
    layout:"AppLayout",
    adminGuard:false
  },
]

export default UserRoutes