import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import wheelAnimation_en from "../../assets/images/English_Wheel.gif";
import wheelAnimation_fr from "../../assets/images/French_Wheel.gif";
import wheelImage_en from "../../assets/images/displayWheel-new.png"
import wheelImage_fr from "../../assets/images/dislayWheel_fr.png"
import logo from "../../assets/images/TommyIcon.png";
import { useTranslation } from "react-i18next";
import authService from "../../services/authServices";
import i18next from "i18next";
import { link } from "../helper/constantsLink/links";
function Home() {
  const currentLanguage = i18next.language;
  const [isAnimationLoaded,setIsAnimationLoaded]=useState(false);
  const handleAnimationLoaded=()=>{
    setIsAnimationLoaded(true)
  }
  const { t } = useTranslation();
  // clear local storage before proceeding.
  useEffect(() => {
    authService.clearUser();
  }, []);
  // adjust padding in button
  const findPaddingForButton=(text)=>{
    let paddingDecided="px-14 pt-4 pb-3"
    const wordsLength = text.split(" ").length
    if(wordsLength<=1)
    {
      return paddingDecided
    }
      paddingDecided= "p-3"
      return paddingDecided
  }

  return (
    <>
      <div>
        <p className="text-2xl p-2 font- text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center mt-2 ">
          <img className="h-7" alt={"THlogo"} src={logo} />
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          {t("shop_spin_line1").toUpperCase()}
        </p>
        <p className="text-[3.35rem] leading-[1] tracking-widest mt-1 lg:text-[4.10rem] font-[futura] text-[#D61233] ">
          <span className="text-[#02154E]">*</span>{t("shop_spin_line2").toUpperCase()}<sup className="text-3xl lg:text-4xl">*</sup>
        </p>
        <p className="text-5xl font-[futura-bold] mt-1 lg:text-6xl text-white">
          {t("shop_spin_line3").toUpperCase()}
        </p>
        <p className="text-5xl font-[futura-bold] mt-1 lg:text-6xl text-white">
          {t("shop_spin_line4").toUpperCase()}
        </p>
      </div>
      {currentLanguage == "fr" ? (
        <div className="grid justify-center">
          <img
            className={`h-64 lg:h-96 mt-12  ${
              isAnimationLoaded ? "block" : "hidden"
            }`}
            onLoad={handleAnimationLoaded}
            src={wheelAnimation_fr}
            alt="wheel-II"
          />
          :
          <img
            className={`mb-12 h-60 lg:h-[22rem]  ${
              isAnimationLoaded ? "hidden" : "block"
            } `}
            src={wheelImage_fr}
            alt="wheel-I"
          />
        </div>
      ) : (
        <div className="grid justify-center">
          <img
            className={`h-64 lg:h-96 ${isAnimationLoaded ? "block" : "hidden"}`}
            onLoad={handleAnimationLoaded}
            src={wheelAnimation_en}
            alt="wheel-II"
          />
          :
          <img
            className={`mb-12 h-60 lg:h-[22rem]  ${
              isAnimationLoaded ? "hidden" : "block"
            } `}
            src={wheelImage_en}
            alt="wheel-I"
          />
        </div>
      )}
      <div className="flex justify-center">
        <Link to="/register">
          <button className={`text-xl font-[futura-bold] ${findPaddingForButton(t('enter'))} lg:text-3xl my-4  text-[#02154E] bg-slate-100  font-extrabold`}>
            {t("enter").toUpperCase()}
          </button>
        </Link>
      </div>
      <p className="text-base font-[futura] text-white my-5">
        {"*" + t("see") + " "}
        <a target="_blank" href={currentLanguage==='fr'?link.terms_fr:link.terms_en}><span className="underline">{t("term_and_conditions")}</span></a>
        {" " + t("for_details")}
      </p>
    </>
  );
}

export default Home;
