import { Fragment, useRef,useEffect,useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import logo from "../../../../../assets/images/TommyIcon.png"
export default function ConfirmCoupons({ open, setOpen, couponsData,handleCreateCoupon,handleModalClose }) {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();
  const [couponsMetaData,setCouponsMetaData]=useState({totalCoupons:0,shortCoupons:0});

  const analyzeCouponData=()=>{
    if(couponsData.length)
    {
      let shortCoupons=0
      setCouponsMetaData({...couponsMetaData,totalCoupons:couponsData.length})
      couponsData.map(item=>{
        if(item.code.length<6)
        {
          return shortCoupons++
        }
      })
      setCouponsMetaData({totalCoupons:couponsData.length,shortCoupons:shortCoupons})
    }
  }
  useEffect(() => {
     analyzeCouponData()
  }, [couponsData])
  
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 h-[80vh]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0  w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white font-[futura-bold] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-[#02154E] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 ">
                      <div>
                        <p className="text-xl p-2  text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center mt-2 ">
                          <img className="h-8" alt={"THlogo"} src={logo} />{" "}
                        </p>
                        <p className="text- xl font-[futura-bold] my-3 lg:text-2xl text-[#D61233]">
                          {t("review_coupons").toUpperCase()}
                        </p>
                
                      </div>
          
                      <div className="grid-cols-1 gap-6 text-white">
                        <p>Total Coupons : {couponsMetaData.totalCoupons}</p>
                        <p>Coupons with detected with less than 6 Chacters : {couponsMetaData.shortCoupons}</p>
                      </div>
                    </div>
               
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={handleCreateCoupon}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleModalClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
