import React from "react";
import {Link,useLocation,useNavigate} from "react-router-dom"
import authService from "../../services/authServices";


const AdminLayout = ({ children,showNavbar }) => {
  const navigate = useNavigate();
  const isDashboard=useLocation().pathname==="/admin/dashboard"
  const handleLogout =()=>{
   navigate("/admin/login")
   authService.clearUser();
  }
  return (
    <>
      <div className="min-h-[85vh] bg-[#02154E]  font-[futura-bold] ">
        <div className=" font-[futura] text-white text-2xl ">
          {showNavbar?<div className={`flex ${!isDashboard?'justify-between':" justify-end"} p-1`}>
            {!isDashboard&&<Link to ="/admin/dashboard"> <button className="bg-[#D61233] px-2 rounded  ">BACK</button></Link>}
            <button className="bg-[#D61233] px-2 rounded  " onClick={handleLogout}>LOGOUT</button>
          </div>:""}
        </div>
        <div className="container mx-auto">{children}</div>
      </div>
    </>
  );
};

export default AdminLayout;
