import React from "react";
import { useState } from "react";
import logo from "../../../../assets/images/TommyIcon.png";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { customInterceptors } from "../../../../services/config";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { AdminEndPoints } from "../../../../services/apiEndPoints";
import ConfirmCoupons from "./couponConfirmationModal/ConfirmCouponsModal";
import CouponTable from "./couponTable/CouponTable";
import authService from "../../../../services/authServices";
import { useNavigate } from "react-router-dom";

function ManageCoupons() {
  const navigate = useNavigate();
  const [couponsData, setCouponsData] = useState([]);
  const [open, setOpen] = useState(false);
  const API = customInterceptors();
  const checkConvertedCsvAndSave = (result) => {
    const { data} = result;
    const isEmptyString = data.find((item) => item?.code?.length <= 3);
    if (isEmptyString) {
      toast.error(
        "Anomalities detected, code with less than 3 Characters Detected"
      );
    }
    setCouponsData(data);
    setOpen(true);
  };
  const handleCreateCoupon = async () => {
    if (couponsData?.length) {
      try {
        const payload = { promotions: couponsData };
        const response = await API.post(
          AdminEndPoints.CREATE_BULK_PROMOTIONAL_CODES,
          payload
        );
        if (response.statusText === "Created") {
          setOpen(false);
          toast.success("Coupons Created Successfully");
        }
      } catch (error) {
        if(error?.response?.status===401)
        {
          authService.clearUser()
          navigate("/admin/login")
          return
  
        }
        toast.error("Some Error Occured");
        if (error?.response?.error?.message) {
          toast.error(error?.response?.error?.message);
        }
      }
    }
  };
  const handleModalClose = () => {
    setCouponsData([]);
    setOpen(false);
  };
  const seeResult = (file) => {
    const csvFile = file[0];
    if (csvFile.type !== "text/csv") {
      toast.error("Please Select CSV file only");
      return;
    }
    if (csvFile) {
      Papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: (result) => {
          checkConvertedCsvAndSave(result);
        },
        header: true, // Set to true if your CSV has a header row
      });
    }
  };

  const { t } = useTranslation();
  return (
    <>
        <p className="text-2xl p-2 font- text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center">
          <img className="h-8" alt="THlogo" src={logo} />
        </p>
         <p className="text-4xl font-[futura] mt-3 lg:text-5xl text-white">
          {t("Coupons List").toUpperCase()}
        </p>
      <CouponTable/>
      <ConfirmCoupons
        open={open}
        setOpen={setOpen}
        couponsData={couponsData}
        handleCreateCoupon={handleCreateCoupon}
        handleModalClose={handleModalClose}
      />
      <div className="min-h-100 grid ">
      
        <p className="text-4xl font-[futura] mt-8 lg:text-5xl text-white">
          {t("create_coupon").toUpperCase()}
        </p>
        <div className="p-4 ">
          <Dropzone onDrop={(acceptedFiles) => seeResult(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className=" border-2 h-56  border-dashed text-white flex place-items-center justify-center "
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop CSV file here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    </>
  );
}

export default ManageCoupons;
