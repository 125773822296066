import React from "react";
import { BrowserRouter, Routes as AppRoutes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminGuard from "../../services/gaurds/AdminGuard";
import AdminRoutes from "./Routes/AdminRoutes";
import UserRoutes from "./Routes/UserRoutes";
import AppLayout from "../layouts/AppLayout";
import Home from "../../components/home/Home";
import AdminLayout from "../layouts/AdminLayout";

function Router() {
  const routes = [...AdminRoutes, ...UserRoutes];
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <div className="h-[2.5vh] bg-[#02154E]"></div>
        <div className="h-[2.5vh] bg-white"></div>
        <div className="h-[2.5vh] bg-[#D61233]"></div>
        <AppRoutes>
          {routes.map((route) => (
            <Route
              path={route.path}
              element={
                <AdminGuard adminGuard={route.adminGuard}>
                  {route.layout === "AppLayout" && (
                    <AppLayout>{route.component}</AppLayout>
                  )}
                  {route.layout === "AdminLayout" && (
                    <AdminLayout showNavbar={route.showNavbar}>
                      {route.component}
                    </AdminLayout>
                  )}
                </AdminGuard>
              }
            />
          ))}
          <Route
            path="*"
            element={
              <AppLayout>
                <Home />
              </AppLayout>
            }
          />
        </AppRoutes>
        <div className="h-[2.5vh] bg-[#D61233]"></div>
        <div className="h-[2.5vh] bg-white"></div>
        <div className="h-[2.5vh] bg-[#02154E]"></div>
      </BrowserRouter>
    </>
  );
}

export default Router;
