import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import spinnerStar from "../../assets/images/spin2.png";
import cursorIcon from "../../assets/images/coursorimg.png";
import { PrizeDataForSpinWheel,PrizeDataForSpinWheel_fr } from "../helper/PrizeReference";
import pointerImage from "../../assets/images/pointer.png";
import logo from "../../assets/images/TommyIcon.png";
import { customInterceptors } from "../../services/config";
import { EndPoints } from "../../services/apiEndPoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import authService from "../../services/authServices";
import Border from "./Border";
import CryptoJS from "crypto-js";
import spinAudio from "../../assets/audio/wheel_rotate_sound.mp3"
const API = customInterceptors();

function Roullete({ setPrizeDescription, setShowPrize, userDetails,currentLanguage }) {
  const sound = new Audio(spinAudio)
  let isFrench = false;
  if (currentLanguage === "fr") isFrench = true;
  const { t } = useTranslation();
  //states
  const [mustSpin, setMustSpin] = useState(false);
  const [disableSpinning,setDisableSpinning] = useState(false);
  const [prizePayload, setPrizePayload] = useState({
    customerId: "",
    rewardId: "",
    rewardValue: "",
  });

  // Function to handle spin button click
  const handleSpinClick = async () => {
    try {
      if (!mustSpin&&!disableSpinning) {
        setDisableSpinning(true)
        const res = await API.post(EndPoints.CREATE_WIN);
        // Check if the response status is one of the success statuses
        if ([200, 201, 202].includes(res?.status)) {
          //decrypt response
          const key = process.env.REACT_APP_REWARD_HASH_KEY;
          const iv = CryptoJS.enc.Hex.parse(
            process.env.REACT_APP_REWARD_IV_KEY
          );
          const encryptedText = CryptoJS.enc.Hex.parse(res.data.cipher);
          const decipher = CryptoJS.AES.decrypt(
            { ciphertext: encryptedText },
            CryptoJS.enc.Utf8.parse(key),
            {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          );
          const decryptedPrize = CryptoJS.enc.Utf8.stringify(decipher);
          const prizeObject = JSON.parse(decryptedPrize);
          const prizeDescription = prizeObject.name;

          // Set prize payload and description, and set mustSpin to true
          setPrizePayload({
            customerId: res.data.user_id,
            rewardId: prizeObject.id,
            rewardValue: prizeObject.value,
            isFrench:isFrench
          });
          setPrizeDescription(prizeDescription);
          setMustSpin(true);
          sound.play();
        } else {
          // Display an error message if some error occurred
          toast.error("Some Error Occurred");
          setDisableSpinning(false)
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        // Display an error message if there's an error in the response
        toast.error(err?.response?.data?.message);
        setDisableSpinning(false)
      }
    }
  };

  // Function to handle stopping the spinning animation
  const handleStopSpinning = async () => {
    setMustSpin(false);
    sound.pause();
    const res = await API.post(EndPoints.CONFIRM_WIN, prizePayload);
    // Check if the response contains a valid reward ID
    if (res?.data?.reward?.id) {
      setTimeout(() => setShowPrize(true), 1000);
      // Clear user data in authService
      authService.clearUser();
    }
  };

  return (
    <>
      <div className="text-2xl p-3 mt-5 px-8 text-white tracking-widest lg:tracking-[0.3em] flex justify-center lg:justify-end lg:-mb-10 place-items-center   ">
        <img className="h-full md:h-8" alt={"THlogo"} src={logo} />{" "}
      </div>
      <div className="overflow-hidden flex cursor-pointer">
        <div
          onClick={handleSpinClick}
          className="parent-container relative mt-2 bg-red-600"
        >
          <div className="absolute h-[100%] w-[100%]">
            <div className="grid h-[100%] w-[100%]  justify-center place-content-center">
              <img
                height={110}
                width={110}
                style={{ zIndex: 200 }}
                src={spinnerStar}
                alt="spinnerStart-I"
              />
            </div>
          </div>
          <div className="absolute h-[100%] w-[100%]">
            <Border mustSpin={mustSpin} />
          </div>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizePayload.rewardValue}
            data={isFrench?PrizeDataForSpinWheel_fr:PrizeDataForSpinWheel}
            outerBorderColor={"#ca041b"}
            radiusLineColor="none"
            innerRadius={10}
            outerBorderWidth={3}
            spinDuration={0.8}
            pointerProps={{
              src: pointerImage,
              style: {
                rotate: "-40deg",
                transform: "translateX(-25px)",
              },
            }}
            onStopSpinning={handleStopSpinning}
          ></Wheel>
        </div>
      </div>
      <div className="flex justify-end w-[95%]">
        <div className="-mt-16 xl:-mt-32 2xl:-mt-52 mr-4 2xl:mr-16 p-3">
          <img
            className="h-auto my-5 mx-auto"
            src={cursorIcon}
            alt="pointer-I"
          />
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            {t("tap_to_spin").toUpperCase()}
          </p>
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            {t("the_wheel").toUpperCase()}
          </p>
        </div>
      </div>
    </>
  );
}

export default Roullete;
